:root {
    --whitewhite: #FFFFFF;
    --brown-background: #171516;
    --black-background: #000000;
    --primary-brown: #45345E;
    --light-blue: #5EEAD4;
    --danger: #CA024F;

    --floater-size: 100px;
}

    /* From https://css.glass */
