// Responsive size
$breakpoint_phone: 480px;
$breakpoint_tablet: 768px;
$breakpoint_medium: 1024px;
$breakpoint_desktop: 1200px;
$max_width: $breakpoint_desktop;

//----------
// General scss for the breakpoints
//----------
@mixin breakpoint($size:$breakpoint_tablet) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $breakpoint_phone) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $breakpoint_tablet) {
    @content;
  }
}

@mixin medium {
  @media screen and (max-width: $breakpoint_medium) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $breakpoint_desktop) {
    @content;
  }
}



/*
Responsive font size
*/
@mixin font-size($size, $rapp: 1) {
  font-size: $size;

  //@include desktop {
  //  font-size: $size - $rapp;
  //}

  @include tablet {
    font-size: ($size - (2 * $rapp));
  }

  @include phone {
    font-size: ($size - (4 * $rapp));
  }
}

@mixin line-height($size, $rapp: 1) {
  line-height: $size;

  @include desktop {
    line-height: $size - $rapp;
  }

  @include tablet {
    line-height: ($size - (2 * $rapp));
  }

  @include phone {
    line-height: ($size - (4 * $rapp));
  }
}