@import "globals";
@import "mixins";
:root {
  --max-content-width: 1212px;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body {
  width: 100%;
  font-family: Inter, sans-serif;
  box-sizing: border-box;
  color: var(--whitewhite);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: .03em;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.nav {
  background: var(--brown-background);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 24px 48px;
  color: var(--whitewhite);
  @include phone(){
    padding: 24px 12px;
  }
  &-links {
    display: flex;
    align-items: center;
    gap: 32px;
    a {
      color: var(--whitewhite)
    }
    @include phone(){
      gap: 16px;
    }
  }
}
.hero {
  width: 100%;
  color: var(--whitewhite);
  background-color: var(--brown-background);
  padding: 60px 90px;
  background-image: url("../images/hero-bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 80vh;
  position: relative;
  @include desktop(){
    padding: 60px 16px;
    height: auto;
  }
  @include tablet(){
    display: block;
  }
  &-canvas {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 84px;
    left: 0;
    z-index: 50;
    pointer-events: none;
  }
  .glass-card {
    width: 50%;
    max-width: 700px;
    /* From https://css.glass */
    background: rgba(217, 217, 217, 0.15);
    border: 1px solid #FFF9F9;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 80px 40px;
    @include desktop(){
      padding: 80px 16px;
    }
    @include tablet(){
      width: 100%;
      padding: 40px 16px;
    }
    @include phone(){
      @include font-size(70px, 3);
    }
    &-text {
      @include font-size(48px, 2);
      line-height: 150%;
      @include phone(){
        @include font-size(45px, 3);
      }

      .name {
        @include font-size(90px, 3);
        line-height: 150%;
        font-weight: 600;
        color: var(--primary-brown);
        @include phone(){
          @include font-size(70px, 3);
        }
      }
    }
  }

  &-img {
    width: 50%;
    text-align: center;
    position: relative;
    @include tablet(){
      width: 100%;
      height: 350px;
    }
    &-bg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #45345E;
      clip-path: polygon(0 40%, 100% 40%, 100% 100%, 0% 100%);
    }

    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;

    }
  }

}
.testimonial {
  padding: 80px 16px;
  text-align: center;
  background-color: #171516;
  @include tablet(){
    padding: 40px 16px;
  }
  &-content {
    margin: 0 auto;
    width: 80%;
    @include tablet(){
      width: 100%;
    }
  }
  &-head {
    @include font-size(38px, 2);
    font-weight: bold;
    letter-spacing: .05em;
  }
  &-text {
    background: url('../images/left-quotes-sign.png') no-repeat top left;
    background-size: 30px auto;
    margin: 50px 0;
    line-height: 150%;
    font-size: 1.2rem;
    padding: 30px;
    @include font-size(18px);
    p {
      margin-bottom: 16px;
    }
    @include tablet(){
      background-size: 20px auto;
      padding: 20px;
    }
    @include phone(){
      background-size: 15px auto;
      padding: 15px;
    }
  }
  &-author {
    //background: url('../images/left-quotes-sign.png') no-repeat top right;
    //background-size: 30px auto;
    display: flex;
    justify-content: center;
    margin: 20px 0;
    align-items: center;
    &-image {
      margin-right: 20px;
      &-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
    }
    &-info {
      text-align: left;
      &-head {
        font-weight: bold;
      }
      &-text {
        font-style: italic;
      }
    }
  }
}
.footer {
  color: var(--whitewhite);
  background-color: var(--black-background);
  text-align: center;
  padding: 48px 16px;
  &-head {
    font-weight: bold;
    letter-spacing: .05em;
    @include font-size(38px);
  }
  &-text {
    @include font-size(18px);
    margin: 48px 0
  }
  &-email {
    margin-bottom: 24px;
    a {
      color: var(--primary-brown);
      font-weight: 600;
    }
  }
  &-social {
    display: flex;
    justify-content: center;
    gap: 32px;
    &-item {
      display: inline-block;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
}
.portfolio-page {
  background-color: var(--brown-background);
  &-head {
    display: flex;
    gap: 35px;
    align-items: center;
    color: var(--light-blue);
    @include font-size(26.4px);
    font-weight: 700;
    line-height: 35.2px; /* 133.333% */
    letter-spacing: 2.64px;
    text-transform: uppercase;
    padding: 60px 48px;
    @include tablet(){
      padding: 60px 24px;
    }
    @include phone(){
      padding: 60px 12px;
      .line {
        max-width: 40px;
      }
    }
  }
  .category {
    display: flex;
    padding-bottom: 75px;
    justify-content: center;
    gap: 32px;
    flex-wrap: wrap;
    @include tablet(){
      gap: 16px;
    }
    &-btn {
      padding: 16px 32px;
      border-radius: 16px;
      background: none;
      outline: none;
      border: 1px solid var(--whitewhite);
      color: var(--whitewhite);
      &.active {
        color: var(--brown-background);
        background: var(--whitewhite);
      }
      @include tablet(){
        padding: 8px 16px;
        border-radius: 8px;
      }
      //@include tablet(){
      //  padding: 8px 16px;
      //}
    }
  }
  .pp {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 200px;
    a {
      color: var(--whitewhite);
    }
    &-image {
      width: 100%;
      max-width: 800px;
      text-align: center;
      margin-bottom: 75px;
      img {
        width: 100%;
      }

    }
    &-description {
      margin-bottom: 50px;
      &-head {
        color: var(--light-blue);
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      &-text {
        font-size: 20px;
        line-height: 40px;
        p {
          margin-bottom: 16px;
        }
      }
    }
    &-back {
      display: flex;
      justify-content: flex-end;
      &-text {
        display: flex;
        gap: 16px;
        align-items: center;
        color: var(--light-blue) !important;
      }
    }
  }
}
.portfolio-items {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  padding: 0 16px 100px;
  justify-content: center;
  .card {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 250px;
    &:hover {
      .card-info {
        display: block;
      }
    }

    @media (pointer:coarse) {
      .card-info {
        display: block;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      &-img{
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
    &-info {
      display: none;
      position: absolute;
      height: 150px;
      bottom: 0;
      left: 0;
      width: 100%;
      //background-color: var(--brown-background);
      background-image: url("../svg/wave.svg");
      //background-size: 100% 100%;
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 15px;
      animation: bottomUp 1s ;
    }
    &-name {
      padding: 70px 0 0;
      color: #FFF;
      font-family: Segoe UI;
      font-size: 24.099px;
      font-weight: 300;

    }
    &-button {
      &-btn {
        display: flex;
        gap: 10px;
        align-items: center;
        color: white;
      }
    }
  }
}
.about {
  display: flex;
  //padding: 0 48px;
  gap: 32px;
  padding: 0 90px;
  justify-content: space-between;
  @include desktop(){
    padding: 0 16px;
  }
  @include tablet() {
    display: block;
  }
  @include phone() {
    padding: 0;
  }
  &-image {
    min-width: 35%;
    text-align: center;
    max-height: 500px;
    position: relative;
    width: 40%;
    background: var(--whitewhite);
    height: 100%;
    @include tablet() {
      display: none;
    }
    &-img {
      //position: absolute;
      width: auto;
      height: 100%;
      max-height: 500px;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
    }
  }
  &-text {
    min-width: 50%;
    width: 50%;
    margin: 0 0 50px;
    line-height: 150%;
    font-size: 1.2rem;
    @include font-size(18px);
    @include tablet() {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-bottom: 16px;
    }
  }
  &-contact {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 50px;

    a {
      color: var(--whitewhite);
    }

    @include phone() {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.expertise {

  &-head {
    display: flex;
    gap: 24px;
    font-size: 22px;
    font-weight: 600;
    align-items: center;
    img {
      height: 60px;
      width: auto;
    }
  }
  &-text {
    font-weight: 400;
    line-height: 1.4em;
    &:before {
      content: "<h3>";
      margin-bottom: 5px;
    }
    &:after {
      content: "<h3>";
      margin-top: 5px;
    }
    &-main {
      margin-left: 15px;
      padding-left: 35px;
      border-left: 1px solid #FFFFFF;
    }
  }
}
.slida {
  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    max-width: 350px;
    width: 100%;
    height: 300px;
    border: 1px solid var(--whitewhite);
    padding: 20px 10px;
    @include phone(){
      max-width: 300px;
      width: 100%;
      height: 300px;
    }
  }

}
.recent {
  padding: 150px 16px 0;
  &-head {
    text-align: center;
    @include font-size(38px, 2);
    font-weight: bold;
    letter-spacing: .05em;
    margin-bottom: 50px;
  }
  &-btn {
    margin: -20px 0 100px;
    text-align: center;
  }
}
.contact {
  &-page {

  }
  &-head {
    display: flex;
    gap: 35px;
    align-items: center;
    color: var(--light-blue);
    @include font-size(26.4px);
    white-space: pre;
    font-weight: 700;
    line-height: 35.2px; /* 133.333% */
    letter-spacing: 2.64px;
    text-transform: uppercase;
    padding: 60px 48px;
    @include tablet(){
      padding: 60px 24px;
      @include font-size(24px);
    }
    @include phone(){
      padding: 60px 12px;
      @include font-size(20px);
    }
  }
  &-main {
    display: flex;
    gap: 24px;
    @include tablet(){
      display: block;
    }
  }
  &-form {
    width: 60%;
    min-width: calc(60% - 12px);
    position: relative;
    padding: 0 16px;
    @include tablet(){
      width: 100%;
      min-width:auto;
    }
    .form-group {
      margin-bottom: 30px;

    }
    input, textarea {
      width: 100%;
      color: inherit;
      @include font-size(18px);
      font-family: inherit;
      padding: 16px 24px;
      border-radius: 2px;
      background-color: rgba(var(--whitewhite), .5);
      border: none;
      border-bottom: 3px solid transparent;
      //width: 90%;
      display: block;

      &:focus{
        outline: none;
        box-shadow: 0 1rem 2rem rgba(#000000, .1);
        border-bottom: 3px solid var(--brown-background);

        &:invalid{
          border-bottom: 3px solid blue;
        }
      }



      &::-webkit-input-placeholder{
        color: blue;
      }
    }

    label{
      margin-left: 16px;
      //margin-top: -0.7rem;
      //display: block;
      transition: all .3s;
      transform: translateY(-72px);
      display: inline-flex;
      //background: #333;
    }

    input:placeholder-shown + label{
      opacity: 0;
      //visibility: hidden;
      display: none;
    }
  }
  &-info {
    width: 40%;
    min-width: calc(40% - 24px);
    padding: 0 16px;
    &-head {
      @include font-size(24px);
      font-weight: bold;
      letter-spacing: .05em;
      margin-bottom: 30px;
    }
    @include tablet(){
      margin: 100px 0;
      min-width: auto;
      width: 100%;
    }
  }
  .form-control {
    border: none !important;
    background: #333;
    color: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  }
  .form-control:active,
  .form-control:focus {
    background: #404040;
    color: #fff;
  }
  .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }

  .site-form .form-control {
    border: 1px solid #b3bdc6;
  }

  .site-contact-details,
  .site-contact-details li {
    padding: 0;
    margin: 0;
  }

  .site-contact-details li {
    list-style: none;
    margin-bottom: 20px;
  }
  .site-contact-details li a {
    color: var(--whitewhite);
  }
  .site-contact-details li > span {
    color: #4b657e;
    letter-spacing: 0.1em;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }

  .thankyou_message {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(110, 110, 110, 0.7);
  }
  .thankyou_message_text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
  }
  .error-message {
    color: var(--danger);
    padding: 0 16px;
  }
}

.btn {
  &-text {
    outline: none;
    border: none;
    padding: 16px 0;
    cursor: pointer;
    background: none;
    font-size: 1.05rem;
    font-weight: 600;
  }
  &-primary {
    outline: none;
    border: 1px solid var(--whitewhite);
    cursor: pointer;
    font-size: 1.05rem;
    font-weight: 600;
    color: var(--whitewhite);
    background-color: var(--light-blue);
    padding: 16px 32px;
    border-radius: 100px;
  }
}
button {
  white-space: pre;
}

@keyframes bottomUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

